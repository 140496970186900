import M$plugin$Calendar from './/facade/js/calendar';
import M$control$CalendarControl from './/facade/js/calendarcontrol';
import M$impl$control$CalendarControl from './/impl/ol/js/calendarcontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.Calendar = M$plugin$Calendar;
window.M.control.CalendarControl = M$control$CalendarControl;
window.M.impl.control.CalendarControl = M$impl$control$CalendarControl;
